import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import axios from 'axios';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import getValidationErrors from '../../../utils/getValidationErrors';
import CheckboxInput from '../../../components/Inputs/Checkbox';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import Input from '../../../components/Inputs/Input';
import Select from '../../../components/Inputs/Select';
import BotaoSalvar from '../../../components/Botoes/BotaoSalvar';
import { BodyCadastro, FooterCadastro } from './styles';
import IGrupoProduto from '../../../interfaces/selectOptions';

interface IConfiguracaoAdmin {
  config_empresa_id?: number;
  grupos_exibidos: number[];
  operacoes_exibidas: number[];
  bloquear_alteracao_preco: boolean;
  bloquear_alteracao_prazo: boolean;
  bloquear_alteracao_forma_pagamento: boolean;
  bloquear_alteracao_grupo_clientes: boolean;
  bloquear_inclusao_estoque_zero: boolean;
  bloquear_alteracao_tabela_preco: boolean;
}

const Configuracoes: React.FC = () => {
  const [loading_message, setLoadingMessage] = useState('');
  const [grupos_produtos, setGruposProdutos] = useState<IGrupoProduto[]>();
  const [operacoes, setOperacoes] = useState([]);
  const [bloquear_alteracao_preco, setBloquearAlteracaoPreco] = useState(false);
  const [bloquear_alteracao_prazo, setBloquearAlteracaoPrazo] = useState(false);
  const [
    bloquear_alteracao_forma_pagamento,
    setBloquearAlteracaoFormaPagamento,
  ] = useState(false);
  const [bloquear_alteracao_grupo_clientes, setBloquearAlteracaoGrupoClientes] =
    useState(false);
  const [bloquear_inclusao_estoque_zero, setBloquearInclusaoEstoqueZero] =
    useState(false);
  const [bloquear_alteracao_tabela_preco, setBloquearAlteracaoTabelaPreco] =
    useState(false);
  const formRefAdmin = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    axios
      .all([
        api.get('grupo-produto?filtrar=0'),
        api.get(`operacao?filtrar=0`),
        api.get<IConfiguracaoAdmin>(`busca-configuracoes-empresa`),
      ])
      .then(
        axios.spread(
          (
            retorno_grupos_produtos,
            retorno_operacoes,
            retorno_configuracao_empresa,
          ) => {
            setOperacoes(retorno_operacoes.data);
            setGruposProdutos(retorno_grupos_produtos.data);
            setBloquearAlteracaoPreco(
              retorno_configuracao_empresa.data.bloquear_alteracao_preco,
            );
            setBloquearAlteracaoPrazo(
              retorno_configuracao_empresa.data.bloquear_alteracao_prazo,
            );
            setBloquearAlteracaoFormaPagamento(
              retorno_configuracao_empresa.data
                .bloquear_alteracao_forma_pagamento,
            );
            setBloquearAlteracaoGrupoClientes(
              retorno_configuracao_empresa.data
                .bloquear_alteracao_grupo_clientes,
            );
            setBloquearInclusaoEstoqueZero(
              retorno_configuracao_empresa.data.bloquear_inclusao_estoque_zero,
            );
            setBloquearAlteracaoTabelaPreco(
              retorno_configuracao_empresa.data.bloquear_alteracao_tabela_preco,
            );
            formRefAdmin.current?.setData({
              config_empresa_id: retorno_configuracao_empresa.data.id,
              grupos_exibidos: retorno_grupos_produtos.data.filter(grupo =>
                retorno_configuracao_empresa.data.grupos_exibidos?.includes(
                  grupo.value,
                ),
              ),
              operacoes_exibidas: retorno_operacoes.data.filter(operacao =>
                retorno_configuracao_empresa.data.operacoes_exibidas?.includes(
                  operacao.value,
                ),
              ),
              bloquear_alteracao_preco:
                retorno_configuracao_empresa.data.bloquear_alteracao_preco,
              bloquear_alteracao_prazo:
                retorno_configuracao_empresa.data.bloquear_alteracao_prazo,
              bloquear_alteracao_forma_pagamento:
                retorno_configuracao_empresa.data
                  .bloquear_alteracao_forma_pagamento,
              bloquear_alteracao_grupo_clientes:
                retorno_configuracao_empresa.data
                  .bloquear_alteracao_grupo_clientes,
              bloquear_inclusao_estoque_zero:
                retorno_configuracao_empresa.data
                  .bloquear_inclusao_estoque_zero,
              bloquear_alteracao_tabela_preco:
                retorno_configuracao_empresa.data
                  .bloquear_alteracao_tabela_preco,
            });
          },
        ),
      )
      .catch(error => console.log(error));
  }, []);

  const handleSubmitAdmin = useCallback(
    async (data: IConfiguracaoAdmin) => {
      try {
        setLoadingMessage('Salvando...');
        await api.put(`configuracoes-empresa/${data.config_empresa_id}`, data);
        if (mounted.current) {
          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: 'Seu cadastro foi atualizado com sucesso.',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefAdmin.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: err.response?.data?.error,
        });
      } finally {
        setLoadingMessage('');
      }
    },
    [addToast],
  );

  return (
    <Form ref={formRefAdmin} onSubmit={handleSubmitAdmin}>
      <div>
        <span>* Configurações do sistema</span>
      </div>

      <BodyCadastro>
        <Input style={{ display: 'none' }} name="config_empresa_id" />

        <Grid item xs={12} sm={12} md={10} lg={10}>
          <Select
            label="Grupos de produtos exibidos no sistema"
            isSearchable={false}
            options={grupos_produtos}
            closeMenuOnSelect={false}
            isMulti
            name="grupos_exibidos"
            placeholder="Nenhum grupo selecionado..."
          />
        </Grid>

        <Grid item xs={12} sm={12} md={10} lg={10}>
          <Select
            label="Operações exibidas no sistema"
            isSearchable={false}
            options={operacoes}
            closeMenuOnSelect={false}
            isMulti
            name="operacoes_exibidas"
            placeholder="Nenhuma operação selecionada..."
          />
        </Grid>

        <Grid item xs={12} sm={12} md={10} lg={10}>
          <CheckboxInput
            name="bloquear_alteracao_preco"
            label="Bloquear para Vendedores a alteração do Preço de Produtos no módulo Pedido"
            value={bloquear_alteracao_preco}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <CheckboxInput
            name="bloquear_alteracao_prazo"
            label="Bloquear para Vendedores a alteração do Prazo nos módulos Cliente e Pedido"
            value={bloquear_alteracao_prazo}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <CheckboxInput
            name="bloquear_alteracao_forma_pagamento"
            label="Bloquear para Vendedores a alteração da Forma de Pagamento nos módulos Cliente e Pedido"
            value={bloquear_alteracao_forma_pagamento}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <CheckboxInput
            name="bloquear_alteracao_grupo_clientes"
            label="Bloquear para Vendedores a alteração do Grupo de Clientes no módulo Cliente"
            value={bloquear_alteracao_grupo_clientes}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <CheckboxInput
            name="bloquear_alteracao_tabela_preco"
            label="Bloquear para Vendedores a alteração da Tabela de Preços no módulo Cliente"
            value={bloquear_alteracao_tabela_preco}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={10} lg={10}>
          <CheckboxInput
            name="bloquear_inclusao_estoque_zero"
            label="Bloquear para Vendedores a inclusão do item no pedido com estoque menor ou igual a zero"
            value={bloquear_inclusao_estoque_zero}
          />
        </Grid>
      </BodyCadastro>
      <FooterCadastro>
        <BotaoSalvar loading={loading_message} type="submit">
          Salvar
        </BotaoSalvar>
      </FooterCadastro>
    </Form>
  );
};

export default Configuracoes;
