import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import axios from 'axios';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptBrLocale from 'date-fns/locale/pt-BR';
import { FormHandles } from '@unform/core';
import UndoIcon from '@material-ui/icons/ArrowBackIos';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Form } from '@unform/web';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import * as Yup from 'yup';
import { useHistory, Link, useParams } from 'react-router-dom';
import { FiMail, FiPhone } from 'react-icons/fi';
import InfoIcon from '@material-ui/icons/Info';
import { confirmAlert } from 'react-confirm-alert';
import Grid from '@material-ui/core/Grid';
import Select from '../../../components/Inputs/Select';
import getValidationErrors from '../../../utils/getValidationErrors';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import BotaoSalvar from '../../../components/Botoes/BotaoSalvar';
import Input from '../../../components/Inputs/Input';
import { Container, Separador } from '../../../styles/GlobalStyles';
import Loading from '../../../components/Loading';
import CheckboxInput from '../../../components/Inputs/ControlledCheckbox';
import Can from '../../../components/Can';
import IOption from '../../../interfaces/selectOptions';
import {
  HeaderCadastro,
  BodyCadastro,
  FooterCadastro,
  Voltar,
  Inline,
  ContainerInfo,
  TextInfo,
  TextEvento,
} from './styles';
import InputMasked from '../../../components/Inputs/InputMask';
import validaCpf from '../../../utils/validaCpf';
import validaCnpj from '../../../utils/validaCnpj';
import ModalPesquisaEndereco from '../../../components/Modal/ModalPesquisaEndereco';
import {
  tipoPessoa,
  freqOptions,
  diaAtendOptions,
} from '../../../config/Basicos';
import ICliente from '../../../interfaces/cliente';
import 'react-confirm-alert/src/react-confirm-alert.css';
import StatusSincronizacao from '../../../components/Sincronizacao/MostraStatusSincronizacao';
import BotaoNovoPedidoRodape from '../../../components/Botoes/BotaoNovoPedidoRodape';
import DateTime from '../../../components/Inputs/DateTime';
import { useAuth } from '../../../hooks/auth';
import mask from '../../../utils/masks';
import TextArea from '../../../components/Inputs/TextArea';
import retornaObjeto from '../../../utils/buscaObjeto';

interface IBuscaCep {
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
}

interface IRouteParam {
  id?: string;
}

interface CheckboxOption {
  id: string;
  value: string;
  label: string;
}

interface IConfiguracaoAdmin {
  bloquear_alteracao_prazo: boolean;
  bloquear_alteracao_forma_pagamento: boolean;
  bloquear_alteracao_grupo_clientes: boolean;
  bloquear_alteracao_tabela_preco: boolean;
}

const CadastroClientes: React.FC<IRouteParam> = () => {
  const [cpf_cnpj_proibido, setCpfCnpjProibido] = useState('');
  const { user, company, companies } = useAuth();
  const [isJuridica, setIsJuridica] = useState<boolean>(false);
  const [cod_retorno_integracao, setCodRetornoIntegracao] = useState(0);
  const [motivo_integracao, setMotivoIntegracao] = useState('');
  const [prazos, setPrazos] = useState([]);
  const [data_inicio, setDataInicio] = useState();
  const [data_inicio_agenda, setDataInicioAgenda] = useState<Date | null>(null);
  const [erroDataPrevistaRetorno, setErroDataPrevistaRetorno] =
    useState<string>('');
  const [grupo_clientes, setGrupoClientes] = useState([]);
  const [formas_pagamento, setFormasPagamento] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const { id } = useParams<IRouteParam>();
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const [loading_message, setLoadingMessage] = useState('');
  const [tabela_precos, setTabelaPrecos] = useState([]);
  const [checked, setChecked] = useState(false);
  const checkboxOptions: CheckboxOption[] = [
    { id: 'isento', value: 'isento', label: ' Isento' },
  ];
  const [bloquear_alteracao_prazo, setBloquearAlteracaoPrazo] = useState(false);
  const [
    bloquear_alteracao_forma_pagamento,
    setBloquearAlteracaoFormaPagamento,
  ] = useState(false);
  const [bloquear_alteracao_grupo_clientes, setBloquearAlteracaoGrupoClientes] =
    useState(false);
  const [bloquear_alteracao_tabela_preco, setBloquearAlteracaoTabelaPreco] =
    useState(false);

  const [prazo_id, setPrazoId] = useState<number | null>(null);
  const [forma_pagamento_id, setFormaPagamentoId] = useState<number | null>(
    null,
  );
  const [grupo_clientes_id, setGrupoClientesId] = useState<number | null>(null);
  const [tabela_preco_id, setTabelaPrecoId] = useState<number | null>(null);

  const data_texto = format(new Date(data_inicio_agenda), 'EEEE', {
    locale: ptBrLocale,
  });

  const role = companies && company && id ? companies[company.id].role : '';

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!id) return;
    axios
      .all([api.get<IConfiguracaoAdmin>(`busca-configuracoes-empresa`)])
      .then(
        axios.spread(retorno_configuracao_empresa => {
          setBloquearAlteracaoPrazo(
            retorno_configuracao_empresa.data.bloquear_alteracao_prazo,
          );
          setBloquearAlteracaoFormaPagamento(
            retorno_configuracao_empresa.data
              .bloquear_alteracao_forma_pagamento,
          );
          setBloquearAlteracaoGrupoClientes(
            retorno_configuracao_empresa.data.bloquear_alteracao_grupo_clientes,
          );
          setBloquearAlteracaoTabelaPreco(
            retorno_configuracao_empresa.data.bloquear_alteracao_tabela_preco,
          );
        }),
      )
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    async function getCliente(): Promise<void> {
      try {
        setIsLoading(true);
        if (!id) return;

        const { data } = await api.get<ICliente>(`cliente/${id}`);

        let juridica = false;
        if (data.pessoa.juridica) {
          juridica = data.pessoa.juridica;
        } else {
          juridica = data.pessoa.cpf_cnpj && data.pessoa.cpf_cnpj?.length > 11;
        }

        setIsJuridica(juridica);

        setCodRetornoIntegracao(data.cod_retorno_integracao);
        setMotivoIntegracao(data.motivo_integracao);
        setChecked(data.pessoa.rg_insc === 'ISENTO');
        setDataInicioAgenda(data.data_inicio_agenda);
        setPrazoId(data.prazo_id);
        setFormaPagamentoId(data.forma_pagamento_id);
        setGrupoClientesId(data.grupo_cliente_id);
        setTabelaPrecoId(data.tabela_preco_id);

        formRef.current?.setData({
          grupo_cliente_id: data.grupo_cliente_id,
          tabela_preco_id: data.tabela_preco_id,
          prazo_id: data.prazo_id,
          forma_pagamento_id: data.forma_pagamento_id,
          vendedor_id: data.vendedor_id,
          ativo: data.ativo,
          periodicidade: data.periodicidade,
          dia_atendimento: data.dia_atendimento,
          data_inicio_agenda: data.data_inicio_agenda,
          observacao: data.observacao,
          pessoa: {
            juridica,
            nome: data.pessoa.nome,
            apelido: data.pessoa.apelido,
            cpf_cnpj: mask({
              input: data.pessoa.cpf_cnpj,
              tipo: juridica ? 'cnpj' : 'cpf',
            }),
            rg_insc: data.pessoa.rg_insc,
            endereco: {
              cep: mask({ input: data.pessoa.endereco?.cep, tipo: 'cep' }),
              logradouro: data.pessoa.endereco?.logradouro,
              numero: data.pessoa.endereco?.numero,
              complemento: data.pessoa.endereco?.complemento,
              bairro: data.pessoa.endereco?.bairro,
              cidade: data.pessoa.endereco?.cidade,
              estado: data.pessoa.endereco?.estado,
            },
            contato: {
              telefone: mask({
                input: data.pessoa.contato?.telefone,
                tipo: 'fone',
              }),
              celular: mask({
                input: data.pessoa.contato?.celular,
                tipo: 'fone',
              }),
              email: data.pessoa.contato?.email,
              outros: data.pessoa.contato?.outros,
            },
          },
        });
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: err.response.data.message,
        });
      } finally {
        setIsLoading(false);
      }
    }

    axios
      .all([
        api.get(`forma-pagamento`),
        api.get(`prazo`),
        api.get(`grupo-cliente`),
        api.get(`colaborador`),
        api.get(`tabela-preco`),
      ])
      .then(
        axios.spread(
          (
            retorno_formas_pagamento,
            retorno_prazos,
            retorno_grupo_clientes,
            retorno_vendedores,
            retorno_tabela_precos,
          ) => {
            setIsLoading(true);

            setFormasPagamento(retorno_formas_pagamento.data);
            setPrazos(retorno_prazos.data);
            setGrupoClientes(retorno_grupo_clientes.data);
            setVendedores(
              retorno_vendedores.data.map(vendedor => {
                return {
                  value: vendedor.id,
                  label: vendedor?.pessoa?.nome,
                };
              }),
            );
            setTabelaPrecos(retorno_tabela_precos.data);
            if (id) {
              getCliente();
            }
            setIsLoading(false);
          },
        ),
      )
      .catch(error => console.log(error));
  }, [id, addToast]);

  const buscaCepViacep = useCallback(async (cep: string) => {
    try {
      const cepPesquisar = cep.replace(/\D+/g, '');
      if (cepPesquisar.length === 8) {
        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cepPesquisar}/json/`,
        );
        formRef.current?.setFieldValue('pessoa.endereco.estado', data.uf);
        formRef.current?.setFieldValue(
          'pessoa.endereco.cidade',
          data.localidade,
        );
        formRef.current?.setFieldValue(
          'pessoa.endereco.logradouro',
          data.logradouro,
        );
        formRef.current?.setFieldValue('pessoa.endereco.bairro', data.bairro);
        formRef.current?.setFieldValue(
          'pessoa.endereco.codigo_ibge',
          data.ibge,
        );
        formRef.current?.setFieldValue(
          'pessoa.endereco.complemento',
          data.complemento,
        );
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const pesquisaEnderecoViacep = useCallback((endereco: IBuscaCep) => {
    formRef.current?.setFieldValue('pessoa.endereco.cep', endereco.cep);
    formRef.current?.setFieldValue(
      'pessoa.endereco.logradouro',
      endereco.logradouro,
    );
    formRef.current?.setFieldValue(
      'pessoa.endereco.complemento',
      endereco.complemento,
    );
    formRef.current?.setFieldValue('pessoa.endereco.bairro', endereco.bairro);
    formRef.current?.setFieldValue('pessoa.endereco.cidade', endereco.cidade);
    formRef.current?.setFieldValue('pessoa.endereco.estado', endereco.estado);
    formRef.current?.setFieldValue('pessoa.endereco.numero', '');
  }, []);

  function dia_atend() {
    switch (data_texto) {
      case 'segunda-feira':
        return 0;
      case 'terça-feira':
        return 1;
      case 'quarta-feira':
        return 2;
      case 'quinta-feira':
        return 3;
      case 'sexta-feira':
        return 4;
      case 'sábado':
        return 5;
      case 'domingo':
        return 6;
      default:
        return -1;
    }
  }

  const handleSubmit = useCallback(
    async (data: ICliente) => {
      const data_formated = format(
        new Date(data_inicio_agenda) || new Date(),
        'yyyy-MM-dd HH:mm:ss',
      );

      const new_data_inicio = {
        data_inicio_agenda: data_formated,
        dia_atendimento: dia_atend(),
      };

      const new_data = { ...data, ...new_data_inicio };

      try {
        setLoadingMessage('Salvando...');
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          grupo_cliente_id: Yup.string().required(
            'O grupo de clientes é obrigatório',
          ),
          pessoa: Yup.object().shape({
            nome: Yup.string().when('juridica', (juridica: boolean, schem) => {
              return juridica
                ? schem
                    .required('A razão social é obrigatória')
                    .max(
                      100,
                      'A razão social não deve ter mais de 100 caracteres',
                    )
                : schem
                    .required('O nome é obrigatório')
                    .max(100, 'O nome não deve ter mais de 100 caracteres');
            }),
            apelido: Yup.string().when(
              'juridica',
              (juridica: boolean, schem) => {
                return juridica
                  ? schem
                      .required('O nome fantasia é obrigatório')
                      .max(
                        100,
                        'O nome fantasia não deve ter mais de 100 caracteres',
                      )
                  : schem
                      .required('O apelido é obrigatório')
                      .max(
                        100,
                        'O apelido não deve ter mais de 100 caracteres',
                      );
              },
            ),
            cpf_cnpj: Yup.string()
              .transform(value => value.replace(/[^0-9]/g, ''))
              .when('juridica', {
                is: true,
                then: Yup.string()
                  .test('Cnpj', 'Cnpj inválido', value => {
                    return validaCnpj(value || '');
                  })
                  .test(
                    'Cnpj',
                    'Já existe um cliente cadastrado com esse cnpj.',
                    value => {
                      return value !== '' && value !== cpf_cnpj_proibido;
                    },
                  ),
              })
              .when('juridica', {
                is: false,
                then: Yup.string()
                  .test('Cpf', 'Cpf inválido', value => {
                    return validaCpf(value || '');
                  })
                  .test(
                    'Cpf',
                    'Já existe um cliente cadastrado com esse cpf.',
                    value => {
                      return !!value && value !== cpf_cnpj_proibido;
                    },
                  ),
              }),
            observacao: Yup.string().max(
              500,
              'A observação não deve ter mais de 500 caracteres',
            ),

            rg_insc: Yup.string().max(
              20,
              'A inscrição estadual não deve ter mais de 10 caracteres',
            ),
            endereco: Yup.object().shape({
              cep: Yup.string()
                .transform(value => value.replace(/[^0-9]/g, ''))
                .required('O cep é obrigatório')
                .length(8, 'O Cep deve possuir 8 dígitos'),
              logradouro: Yup.string().max(
                80,
                'O logradouro não deve ter mais de 100 caracteres',
              ),
              bairro: Yup.string().max(
                50,
                'O bairro não deve ter mais de 100 caracteres',
              ),
              numero: Yup.string().max(
                15,
                'O número não deve ter mais de 100 caracteres',
              ),
              complemento: Yup.string().max(
                50,
                'O complemento não deve ter mais de 50 caracteres',
              ),
              cidade: Yup.string().required('A cidade é obrigatória'),
              estado: Yup.string().required('A UF é obrigatória'),
            }),
            contato: Yup.object().shape({
              email: Yup.string().email('Digite um e-mail válido'),
              outros: Yup.string().max(100),
            }),
          }),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        const retorno = id
          ? await api.put(`/cliente/${id}`, new_data)
          : await api.post('/cliente', new_data);

        if (mounted.current) {
          setCodRetornoIntegracao(retorno.data.cod_retorno_integracao);
          setMotivoIntegracao(retorno.data.motivo_integracao);
          if (retorno.data.cod_retorno_integracao < 400) {
            addToast({
              type: 'success',
              title: 'Sucesso!',
              description: 'Cliente salvo com sucesso',
            });
            history.push('/clientes/lista');
          } else {
            addToast({
              type: 'error',
              time: 30000,
              title: 'Erro na sincronização!',
              description: (
                <>
                  <p>
                    Ocorreu um erro ao tentar conectar com o sistema Beersales.
                  </p>
                  <p>O cliente foi salvo mas não foi sincronizado.</p>
                </>
              ),
            });
            history.push(`/clientes/cadastro/${retorno.data.id}`);
          }
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: err.response?.data?.message,
        });
      } finally {
        setLoadingMessage('');
      }
    },
    [addToast, history, id, cpf_cnpj_proibido, data_inicio_agenda],
  );

  function handleMessage(cliente_id: number, nome: string): void {
    confirmAlert({
      title: 'Atenção',
      message: `Já existe um cliente cadastrado com esse cpf/cnpj: ${nome}. Deseja carregar os dados?`,
      buttons: [
        {
          label: 'Carregar',
          onClick: () => history.push(`/clientes/cadastro/${cliente_id}`),
        },
        {
          label: 'Cancelar',
          onClick: () => formRef.current?.setFieldValue('pessoa.cpf_cnpj', ''),
        },
      ],
      onClickOutside: () =>
        formRef.current?.setFieldValue('pessoa.cpf_cnpj', ''),
      closeOnEscape: false,
    });
  }

  function handleErrorMessage(mensagem: string): void {
    confirmAlert({
      title: 'Atenção',
      message: mensagem,
      buttons: [
        {
          label: 'Sair',
          onClick: () => 0,
        },
      ],
    });
  }

  function periodo() {
    switch (data_inicio) {
      case 1:
        return 'DIÁRIA';
      case 7:
        return 'SEMANAL';
      case 14:
        return 'QUINZENAL';
      case 30:
        return 'MENSAL';
      case 60:
        return 'BIMESTRAL';
      case 90:
        return 'TRIMESTRAL';
      case 180:
        return 'SEMESTRAL';
      case 365:
        return 'ANUAL';
      default:
        return '';
    }
  }

  async function handleFindCnpjReceita(cnpj: string): Promise<void> {
    if (id || !isJuridica || cnpj.length !== 14) return;
    const response = await api.post(`buscar-empresa-por-cnpj?cnpj=${cnpj}`);

    if (response.data) {
      const { data } = response;
      formRef.current?.setData({
        juridica: 'true',
        pessoa: {
          cpf_cnpj: mask({
            input: cnpj,
            tipo: 'cnpj',
          }),
          nome: data.razao_social,
          apelido: data.nome_fantasia,
          endereco: {
            cep: data.cep,
            logradouro: data.logradouro,
            numero: data.numero,
            complemento: data.complemento,
            bairro: data.bairro,
            cidade: data.cidade,
            estado: data.estado,
          },
          contato: {
            telefone: data.telefone,
            email: data.email,
          },
        },
      });
    }
  }

  async function findCpfCnpj(input: string): Promise<void> {
    try {
      setIsLoading(true);
      const cpf_cnpj = input.replace(/[^0-9]/g, '');
      const response = await api.get(`/cliente/findByCpfCnpj/${cpf_cnpj}`);
      setCpfCnpjProibido(cpf_cnpj);
      if (
        `${id}` !== `${response.data.id}` &&
        (response.data.empresa_id === company.id ||
          !response.data.empresa_id) &&
        (response.data.vendedor_id === user.colaborador_id ||
          !response.data.vendedor_id)
      ) {
        handleMessage(response.data.id, response.data.pessoa.nome);
      } else if (
        `${id}` !== `${response.data.id}` &&
        response.data.empresa_id &&
        response.data.empresa_id !== company.id
      ) {
        formRef.current?.setFieldValue('pessoa.cpf_cnpj', '');
        handleErrorMessage(
          'O Cpf/ Cnpj já foi cadastrado e está vinculado a outra empresa',
        );
      } else if (
        `${id}` !== `${response.data.id}` &&
        response.data.vendedor_id &&
        response.data.vendedor_id !== user.colaborador_id
      ) {
        formRef.current?.setFieldValue('pessoa.cpf_cnpj', '');
        handleErrorMessage(
          'O Cpf/ Cnpj já foi cadastrado e está vinculado a outro vendedor',
        );
      } else {
        setCpfCnpjProibido('');
      }
    } catch (err) {
      setCpfCnpjProibido('');
      const cpf_cnpj = input.replace(/[^0-9]/g, '');
      handleFindCnpjReceita(cpf_cnpj);
    } finally {
      setIsLoading(false);
    }
  }

  function handleIsento(): void {
    setChecked(!checked);
    formRef.current?.setFieldValue('pessoa.rg_insc', !checked ? 'ISENTO' : '');
  }

  const pessoa = useMemo(() => {
    if (isJuridica) {
      return {
        nome: {
          label: 'Razão social *',
          placeholder: 'Informe a razão social',
          maxLength: 100,
        },
        apelido: {
          label: 'Nome fantasia *',
          placeholder: 'Informe o nome fantasia',
          maxLength: 100,
        },
        cpj_cnpj: {
          label: 'Cnpj *',
          placeholder: 'Informe o cnpj',
          mask: '99.999.999/9999-99',
        },
        rg_insc: {
          label: 'Inscrição estadual',
          placeholder: 'Informe a inscrição estadual',
          maxLength: 20,
        },
      };
    }

    return {
      nome: {
        label: 'Nome *',
        placeholder: 'Informe o nome',
        maxLength: 100,
      },
      apelido: {
        label: 'Apelido *',
        placeholder: 'Informe o apelido',
        maxLength: 100,
      },
      cpj_cnpj: {
        label: 'Cpf *',
        placeholder: 'Informe o cpf',
        mask: '999.999.999-99',
      },
      rg_insc: {
        label: 'Inscrição estadual',
        placeholder: 'Informe a inscrição estadual',
        maxLength: 20,
      },
    };
  }, [isJuridica]);

  return (
    <Container>
      <Loading isLoading={!!loading_message || isLoading} />
      <Voltar>
        <Link to="/clientes/lista" title="Voltar para a listagem de clientes">
          <UndoIcon fontSize="large" />
        </Link>
      </Voltar>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <HeaderCadastro>
          <div>
            <h1>{id ? 'EDITAR CLIENTE' : 'NOVO CLIENTE'}</h1>
          </div>
          <div>
            <span>Os campos com * são obrigatórios</span>
          </div>
        </HeaderCadastro>
        <BodyCadastro>
          <Grid
            container
            spacing={1}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Grid item xs={8} sm={8} md={3} lg={3}>
              <Select
                label="Tipo pessoa"
                isSearchable={false}
                options={tipoPessoa}
                value={isJuridica ? tipoPessoa[1] : tipoPessoa[0]}
                onChange={(option: IOption) =>
                  option ? setIsJuridica(option.value) : isJuridica
                }
                name="pessoa.juridica"
              />
            </Grid>
            {id && (
              <Grid item xs={4} sm={4} md={2} lg={2}>
                <Select
                  label="Status"
                  isSearchable={false}
                  options={[
                    { value: true, label: 'Ativo' },
                    { value: false, label: 'Inativo' },
                  ]}
                  name="ativo"
                />
              </Grid>
            )}
          </Grid>

          <Grid item xs={8} sm={8} md={3} lg={3}>
            <InputMasked
              name="pessoa.cpf_cnpj"
              mask={pessoa.cpj_cnpj.mask}
              placeholder={pessoa.cpj_cnpj.placeholder}
              label={pessoa.cpj_cnpj.label}
              onBlur={e => findCpfCnpj(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Input
              name="pessoa.nome"
              label={pessoa.nome.label}
              maxLength={pessoa.nome.maxLength}
              placeholder={pessoa.nome.placeholder}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Input
              name="pessoa.apelido"
              label={pessoa.apelido.label}
              maxLength={pessoa.apelido.maxLength}
              placeholder={pessoa.apelido.placeholder}
            />
          </Grid>
          <Grid
            container
            spacing={1}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <Grid item xs={8} sm={8} md={3} lg={3}>
              <Input
                name="pessoa.rg_insc"
                label={pessoa.rg_insc.label}
                maxLength={pessoa.rg_insc.maxLength}
                placeholder={pessoa.rg_insc.placeholder}
                disabled={checked}
                pattern="[0-9]*"
              />
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={2}
              lg={2}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
              }}
            >
              <CheckboxInput
                name="isento"
                label="Isento"
                options={checkboxOptions}
                checked={checked}
                onChange={handleIsento}
              />
            </Grid>
          </Grid>

          <Separador>
            <h2>
              <LocationOnIcon />
              &nbsp;ENDEREÇO
            </h2>
          </Separador>
          <Inline>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <InputMasked
                mask="99.999-999"
                label="Cep *"
                name="pessoa.endereco.cep"
                placeholder="Cep"
                onBlur={e => buscaCepViacep(e.target.value)}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={2} lg={2}>
              <ModalPesquisaEndereco pesquisaCep={pesquisaEnderecoViacep} />
            </Grid>
          </Inline>
          <Input
            name="pessoa.endereco.codigo_ibge"
            style={{ display: 'none' }}
          />
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Input
              label="Logradouro"
              name="pessoa.endereco.logradouro"
              maxLength={80}
              placeholder="Informe o logradouro"
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2} lg={2}>
            <Input
              label="Número"
              name="pessoa.endereco.numero"
              maxLength={15}
              placeholder="Informe o número"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              label="Complemento"
              name="pessoa.endereco.complemento"
              maxLength={50}
              placeholder="Informe o complemento"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              label="Bairro"
              name="pessoa.endereco.bairro"
              maxLength={50}
              placeholder="Informe o bairro"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              disabled
              label="Cidade *"
              name="pessoa.endereco.cidade"
              placeholder="Informe o cidade"
            />
          </Grid>
          <Grid item xs={2} sm={2} md={1} lg={1}>
            <Input
              disabled
              label="UF *"
              name="pessoa.endereco.estado"
              placeholder="UF"
            />
          </Grid>
          <Separador>
            <h2>
              <PermContactCalendarIcon />
              &nbsp;CONTATOS
            </h2>
          </Separador>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              icon={FiPhone}
              placeholder="Informe o telefone"
              label="Telefone/ Celular"
              name="pessoa.contato.telefone"
              maxLength={500}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              icon={FiPhone}
              placeholder="Informe o telefone"
              label="Telefone/ Celular"
              name="pessoa.contato.celular"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              icon={FiMail}
              label="E-mail"
              name="pessoa.contato.email"
              maxLength={500}
              placeholder="Informe o e-mail"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Input
              label="Contato adicional"
              name="pessoa.contato.outros"
              maxLength={500}
              placeholder="Informe um contato adicional"
            />
          </Grid>
          <Separador>
            <h2>
              <InfoIcon />
              &nbsp;INFORMAÇÕES COMPLEMENTARES
            </h2>
          </Separador>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Select
              name="prazo_id"
              isSearchable={false}
              label="Prazo"
              options={[
                { value: null, label: 'Nenhuma opção selecionada' },
                ...prazos,
              ]}
              placeholder="Selecione um prazo"
              isDisabled={
                !!(
                  id &&
                  bloquear_alteracao_prazo &&
                  role === 'customer-user' &&
                  prazo_id
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Select
              name="forma_pagamento_id"
              isSearchable={false}
              label="Forma de pagamento"
              options={[
                { value: null, label: 'Nenhuma opção selecionada' },
                ...formas_pagamento,
              ]}
              placeholder="Selecione uma forma de pagamento"
              isDisabled={
                !!(
                  id &&
                  bloquear_alteracao_forma_pagamento &&
                  role === 'customer-user' &&
                  forma_pagamento_id
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Select
              name="grupo_cliente_id"
              isSearchable={false}
              label="Grupo de clientes *"
              options={grupo_clientes}
              placeholder="Selecione um grupo"
              isDisabled={
                !!(
                  id &&
                  bloquear_alteracao_grupo_clientes &&
                  role === 'customer-user' &&
                  grupo_clientes_id
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Select
              name="tabela_preco_id"
              isSearchable={false}
              label="Tabela de Preços"
              options={[
                { value: null, label: 'Nenhuma opção selecionada' },
                ...tabela_precos,
              ]}
              placeholder="Selecione uma Tabela"
              isDisabled={
                !!(
                  id &&
                  bloquear_alteracao_tabela_preco &&
                  role === 'customer-user' &&
                  tabela_preco_id
                )
              }
            />
          </Grid>
          <Can roles={['customer-admin']}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Select
                name="vendedor_id"
                isSearchable={false}
                label="Vendedor"
                options={[
                  { value: null, label: 'Nenhuma opção selecionada' },
                  ...vendedores,
                ]}
                placeholder="Selecione um vendedor"
              />
            </Grid>
          </Can>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextArea
              name="observacao"
              label="Observação"
              maxLength={500}
              rows={1}
            />
          </Grid>
          <Separador>
            <h2>
              <CalendarIcon />
              &nbsp;AGENDA
            </h2>
          </Separador>
          <Grid container spacing={1} style={{ padding: 0, margin: '0' }}>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={3}
              style={{
                display: 'flex',
                marginTop: 15,
                alignItems: 'flex-end',
              }}
            >
              <DateTime
                label="Data de início"
                value={data_inicio_agenda}
                format="dd/MM/yyyy hh:mm"
                setValue={setDataInicioAgenda}
                name="Data de início"
              />
            </Grid>
            <Grid item xs={8} sm={8} md={3} lg={3}>
              <Select
                label="Frequência"
                options={[
                  {
                    value: null,
                    label: 'Nenhuma opção selecionada',
                  },
                  ...freqOptions,
                ]}
                isSearchable={false}
                value={retornaObjeto(freqOptions, data_inicio)}
                onChange={e => setDataInicio(e.value)}
                name="periodicidade"
                placeholder="Nenhuma opção selecionada"
              />
            </Grid>
          </Grid>
          {data_inicio_agenda && data_inicio ? (
            <ContainerInfo>
              <TextInfo>
                Serão criados eventos na Agenda de forma
                <TextEvento> {periodo()} </TextEvento> a cada
                <TextEvento> {data_texto} </TextEvento>
              </TextInfo>
            </ContainerInfo>
          ) : null}
        </BodyCadastro>
        <FooterCadastro>
          {id && (
            <StatusSincronizacao
              tipo="cliente"
              id={Number(id)}
              cod_retorno_integracao={cod_retorno_integracao}
              motivo_integracao={motivo_integracao}
            />
          )}
          <BotaoSalvar loading={loading_message} type="submit">
            Salvar
          </BotaoSalvar>
        </FooterCadastro>
      </Form>
      <BotaoNovoPedidoRodape />
    </Container>
  );
};

export default CadastroClientes;
