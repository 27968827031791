import styled from 'styled-components';
import { shade } from 'polished';
import {
  backgroundColorLigthButton,
  colorLigthButton,
} from '../../../config/Colors/Ligth';

export const Container = styled.button`
  background: ${backgroundColorLigthButton};
  color: ${colorLigthButton};
  max-height: 100%;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: none;
  padding: 10px 10px;
  border-radius: 4px;
  transition: all 200ms;
  &:disabled {
    background: ${shade(0.3, backgroundColorLigthButton)};
  }
  &:hover {
    background: ${shade(0.3, backgroundColorLigthButton)};
  }
`;
