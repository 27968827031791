// src/pages/Configuracoes
import React from 'react';
import { Tabs as Tab } from '@mui/base/Tabs';
import { Tabs, TabPanels, TabsLists } from '../../components/Abas';

import { Container } from '../../styles/GlobalStyles';
import BotaoNovoPedidoRodape from '../../components/Botoes/BotaoNovoPedidoRodape';
import Can from '../../components/Can';
import TabAdmin from './Admin';
import TabPedidos from './Pedidos';

const Configuracoes: React.FC = () => {
  return (
    <Container>
      <Tab defaultValue={0}>
        <Can roles={['customer-admin']}>
          <TabsLists>
            <Tabs>Configurações do pedido</Tabs>
            <Tabs>Admin</Tabs>
          </TabsLists>
        </Can>
        <Can roles={['customer-user']}>
          <TabsLists>
            <Tabs>Configurações do pedido</Tabs>
          </TabsLists>
        </Can>
        <TabPanels value={0}>
          <TabPedidos />
        </TabPanels>
        <TabPanels value={1}>
          <TabAdmin />
        </TabPanels>
      </Tab>
      <BotaoNovoPedidoRodape />
    </Container>
  );
};

export default Configuracoes;
